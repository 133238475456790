import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";

import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";

interface FiltersVisibility {
    isVisible: boolean
}

export enum FilterStep {
    SelectStore
}

interface FiltersState {
    filtersVisibility: FiltersVisibility,
    activeStep: FilterStep,
    candidateStore?: CostReductionOpportunityByStore
}

const initialState: FiltersState = {
    filtersVisibility: {
        isVisible: false
    },
    activeStep: FilterStep.SelectStore,
    candidateStore: undefined
};

const filtersSlice = createSlice({
    name: "customer/insights/portfolio/filters",
    initialState,
    reducers: {
        showFilters: (state) => {
            state.activeStep = FilterStep.SelectStore;
            state.filtersVisibility.isVisible = true;
        },
        hideFilters: (state) => {
            state.filtersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<FilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setCandidateStore: (state, action: PayloadAction<CostReductionOpportunityByStore>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = initialState.candidateStore;
        }
    }
});

export const {
    showFilters,
    hideFilters,
    setActiveStep,
    clearActiveStep,
    setCandidateStore,
    clearCandidateStore
} = filtersSlice.actions;

export const clearFilters = (): AppThunk => async (dispatch) => {
    dispatch(filtersSlice.actions.hideFilters());
    dispatch(filtersSlice.actions.clearActiveStep());
    dispatch(filtersSlice.actions.clearCandidateStore());
};

export const selectFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.insights.cost.filters.filtersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.insights.cost.filters.activeStep;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.insights.cost.filters.candidateStore;
};

export default filtersSlice;
