import { combineReducers } from "redux";

import slice from "./productSlice";
import partnerFilters from "./partnerFilters";

const reducer = combineReducers({
    root: slice.reducer,
    partnerFilters
});

export default reducer;
