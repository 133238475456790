import React from "react";

import { Box, Container, Divider, Grid, List, ListItem, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { ProductChapter, selectCurrentChapter, selectSelectedPartner, setCurrentChapter } from "modules/customer/tools/product/productSlice";
import { useAppDispatch, useAppSelector } from "store";
import Partners from "pages/customer/tools/product/filters/partners/Partners";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";

interface NavigationProps {
    isSetupComplete: boolean
}

const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
    const { isSetupComplete } = props;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const currentChapter = useAppSelector(selectCurrentChapter);
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const featureFlags = useAppSelector(selectFeatureFlags);

    const chooseChapter = (newChapter: ProductChapter) => {
        dispatch(setCurrentChapter(newChapter));
    };

    return (
        // @ts-ignore
        <Box bgcolor={theme.palette.background.insight} height="100vh">
            <Container maxWidth={false}>
                <br />
                <Grid container>
                    <Grid md="auto">
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            {`Partner: ${selectedPartner?.name ?? ""}`}
                        </Typography>
                        <Box paddingBottom={1}>
                            <Typography variant="h3" >
                                Product
                            </Typography>
                        </Box>
                    </Grid>
                    <Spacer />
                    <Grid md="auto">
                        <Partners />
                    </Grid>
                </Grid>
                <Divider />
                {isSetupComplete && (
                    <Box paddingTop={2} paddingBottom={2}>
                        <List disablePadding>
                            <ListSubheader disableGutters>
                                Product
                            </ListSubheader>
                            <ListItem
                                button
                                selected={currentChapter === ProductChapter.ProductOverview}
                                onClick={() => chooseChapter(ProductChapter.ProductOverview)}
                            >
                                <ListItemText primary="Product overview" />
                            </ListItem>
                            <ListItem
                                button
                                selected={currentChapter === ProductChapter.ProductOpportunities}
                                onClick={() => chooseChapter(ProductChapter.ProductOpportunities)}
                            >
                                <ListItemText primary="Product opportunities" />
                            </ListItem>
                            {(featureFlags.enableCustomerToolsProductStoreOverview ||
                                featureFlags.enableCustomerToolsProductStoreOpportunities) && <ListSubheader disableGutters>
                                    Store
                                </ListSubheader>}
                            {featureFlags.enableCustomerToolsProductStoreOverview && <ListItem
                                button
                                selected={currentChapter === ProductChapter.StoreOverview}
                                onClick={() => chooseChapter(ProductChapter.StoreOverview)}
                            >
                                <ListItemText primary="Store overview" />
                            </ListItem>}
                            {featureFlags.enableCustomerToolsProductStoreOpportunities && <ListItem
                                button
                                selected={currentChapter === ProductChapter.StoreOpportunities}
                                onClick={() => chooseChapter(ProductChapter.StoreOpportunities)}
                            >
                                <ListItemText primary="Store opportunities" />
                            </ListItem>}
                            <ListSubheader disableGutters>
                                Product store fit
                            </ListSubheader>
                            <ListItem
                                button
                                selected={currentChapter === ProductChapter.ProductStoreFit}
                                onClick={() => chooseChapter(ProductChapter.ProductStoreFit)}
                            >
                                <ListItemText primary="Product store fit" />
                            </ListItem>
                        </List>
                    </Box>
                )
                }
            </Container >
        </Box >
    );
};

export default Navigation;
