import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import CostOverviewSubchapter from "./costOverview/CostOverview";
import CostReductionOpportunities from "./costReductionOpportunities/CostReductionOpportunities";

const CostOverview: React.FC = () => {
    return (
        <Chapter
            title="Cost overview"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="cost-overview-chapter"
        >
            <Subchapter
                title="Cost overview"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="cost-overview-subchapter"
            >
                <CostOverviewSubchapter />
            </Subchapter>
            <Subchapter
                title="Cost reduction opportunities"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="cost-reduction-opportunities-subchapter"
            >
                <CostReductionOpportunities />
            </Subchapter>
        </Chapter>
    );
};

export default CostOverview;
