import React from "react";

import { Grid } from "@material-ui/core";

import Insight from "./insight/Insight";
import Navigation from "./navigation/Navigation";
import { clearProduct, loadProduct, selectSelectedPartner } from "modules/customer/tools/product/productSlice";
import { useAppDispatch, useAppSelector } from "store";
import { showPartnerFilters } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";

const Product: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const isSetupComplete = !!selectedPartner;

    React.useEffect(() => {
        dispatch(loadProduct());
        dispatch(showPartnerFilters());
        return () => {
            dispatch(clearProduct());
        };
    }, [dispatch]);
    return (
        <Grid container data-cy="pages-customer-tools-product">
            <Grid item xs={3}>
                <Navigation isSetupComplete={isSetupComplete} />
            </Grid>
            <Grid item xs={9}>
                <Insight isSetupComplete={isSetupComplete} />
            </Grid>
        </Grid>
    );
};

export default Product;
