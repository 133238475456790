import { ResultSet } from "@cubejs-client/core";

import { createAppAsyncThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CostsByStore {
    public readonly id: string;
    public readonly storeId: string;
    public readonly costName: string;
    public readonly costValue: number;

    constructor(
        storeId: string,
        costName: string,
        costValue: number,
    ) {
        this.id = `${storeId}-${costName}`;
        this.storeId = storeId;
        this.costName = costName;
        this.costValue = costValue;
    }
}

export const loadCostsByStores = createAppAsyncThunk<
    CostsByStore[],
    { referenceDate: DateTime }
>(
    "customer/insights/cost/loadCostsByStores",
    async ({ referenceDate }, thunkAPI) => {
        try {
            // ToDo: Update query to bring through similar stores from [analytics].[tbl_SimilarStore] once it's ready
            const query = {
                measures: [
                    "F_Cost.SumCostValue"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day'), referenceDate.endOf('day')]
                }],
                dimensions: [
                    "D_Store.StoreNaturalID",
                    "D_Account.AccountName",
                ],
                segments: ["D_Store.OpenPhysicalStores"],
                limit: 50000
            };
            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;

            return resultSet.rawData().map(row => new CostsByStore(
                row["D_Store.StoreNaturalID"],
                row["D_Account.AccountName"],
                Number(row["F_Cost.SumCostValue"]),
            ));
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading costsByStore."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
