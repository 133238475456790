import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import CostDriversSubchapter from "./costDrivers/CostDrivers";
import Correlations from "./correlations/Correlations";

const CostDrivers: React.FC = () => {
    return (
        <Chapter
            title="Cost drivers"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="cost-drivers-chapter"
        >
            <Subchapter
                title="Cost drivers"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="cost-drivers-subchapter"
            >
                <CostDriversSubchapter />
            </Subchapter>
            <Subchapter
                title="Correlations"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="correlations-subchapter"
            >
                <Correlations />
            </Subchapter>
        </Chapter>
    );
};

export default CostDrivers;
