import React from "react";

import { Box, Card, CardActionArea, CardActions, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        height: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent"
    }
}))(Card);

const StyledCardActionArea = withStyles(() => ({
    root: {
        height: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardContent);

interface StoreCardProps {
    store: CostReductionOpportunityByStore,
    selected: boolean,
    onSelect: (store: CostReductionOpportunityByStore) => void,
    onDeselect: (store: CostReductionOpportunityByStore) => void
}

const StoreCard: React.FC<StoreCardProps> = (props) => {
    const { store, selected, onSelect, onDeselect } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(store);
        } else {
            onSelect(store);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardContent>
                    <h3>{store?.storeName}</h3>
                </StyledCardContent>
                <CardActions>
                    <Box>
                        <Typography variant="subtitle1" component="div">
                            {store?.costName}
                        </Typography>
                    </Box>
                </CardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default StoreCard;
