import React from "react";

import { Box, Typography } from "@material-ui/core";


const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Product setup
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    To continue to the module insights you will need to select a partner
                </Typography>
            </Box>
        </>
    );
};

export default Title;
