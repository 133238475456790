import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import StoreClustering from "./storeClustering/StoreClustering";
import CostOverTime from "./costOverTime/CostOverTime";

const CostOverview: React.FC = () => {
    return (
        <Chapter
            title="Store costs"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            dataCy="store-costs-chapter"
        >
            <Subchapter
                title="{Store name} store clustering"
                subtitle=""
                dataCy="store-clustering-subchapter"
            >
                <StoreClustering />
            </Subchapter>
            <Subchapter
                title="{Cost type} costs over time"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                dataCy="cost-over-time-subchapter"
            >
                <CostOverTime />
            </Subchapter>
        </Chapter>
    );
};

export default CostOverview;
