import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";

import Progress from "./Progress";
import Error from "./Error";

const useStyles = makeStyles(() => ({
    card: {
        width: "100%",
        height: "100%"
    }
}));

const KPIFact = (props) => {
    const classes = useStyles();
    const { loading, error, label, value, disabled} = props;

    const theme = useTheme();
    const color = disabled ? theme.palette.text.disabled: '#FFFFFF';

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Card elevation={0} className={classes.card} data-cy="kpi-card-fact">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="div" align="center">
                            <span style={{color:color}}>
                                {value}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div" align="center">
                            <span style={{color:color}}>
                                {label}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

KPIFact.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

KPIFact.defaultProps = {
    loading: false,
    error: false,
    label: "",
    value: "",
    disabled: false
};

export default KPIFact;
