import React from "react";

import CostOverview from "./costOverview/CostOverview";
import CostDrivers from "./costDrivers/CostDrivers";
import StoreCosts from "./storeCosts/StoreCosts";

import { CostChapter, selectCurrentChapter } from "modules/customer/insights/cost/costSlice";
import { useAppSelector } from "store";

const Insight: React.FC = () => {
    const currentChapter = useAppSelector(selectCurrentChapter);

    return (
        <>
            {currentChapter === CostChapter.CostOverview && (
                <CostOverview />
            )}
            {currentChapter === CostChapter.CostDrivers && (
                <CostDrivers />
            )}
            {currentChapter === CostChapter.StoreCosts && (
                <StoreCosts />
            )}
        </>
    );
};

export default Insight;
